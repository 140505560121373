import request from '@/service/lib/request';
// 抖音视频

/**
 * 分页
 * @author lb
 */
export function getVideoPage(data) {
    return request({
      url: '/dy_video/get_video_list',
      method: 'post',
      data
    });
}

export function refreshVideo(data) {
    return request({
      url: '/dy_video/refresh_video',
      method: 'post',
      data
    });
}

/**
 * 创建视频
 * @author lb
 */
export function createVideo(data) {
  return request({
    url: '/dy_video/create_video',
    method: 'post',
    data
  });
}
