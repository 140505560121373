<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="handleRefreshMedia">刷新列表</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="title"
                label="标题"
                width="120">
                <template slot-scope='scope'>
                  <el-tooltip class="item" effect="dark" :content="scope.row.title" placement="top">
                    <span>{{ scope.row.title.length > 10 ? scope.row.title.substring(0, 10) : '' }}</span>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="cover"
                label="视频封面"
                width="120">
                <template slot-scope='scope'>
                  <img :src="scope.row.cover" width="70px" >
                </template>
            </el-table-column>
            <el-table-column
                prop="playCount"
                label="播放数"
                width="60">
            </el-table-column>
            <el-table-column
                prop="diggCount"
                label="点赞数"
                width="60">
            </el-table-column>
            <el-table-column
                prop="commentCount"
                label="评论数"
                width="60">
            </el-table-column>
            <el-table-column
                prop="shareCount"
                label="分享数"
                width="60">
            </el-table-column>
            <el-table-column
                prop="forwardCount"
                label="转发数"
                width="60">
            </el-table-column>
            <el-table-column
                prop="shareUrl"
                label="视频播放页面"
                align="center"
                width="100">
                <template slot-scope='scope'>
                  <a :href="scope.row.shareUrl" target="_blank">跳转</a>
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='500px' :before-close='cancel'>
      <el-select v-model="uploadForm.openId" placeholder="请选择">
        <el-option
          v-for="item in dyUser"
          :key="item.id"
          :label="item.userId + '-' + item.nickname + '-' + item.openId"
          :value="item.openId">
        </el-option>
      </el-select>
      <p></p>
        <el-upload
          class="upload-demo"
          :action="upload.url"
          :headers="upload.headers"
          :data="uploadForm"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="handleSuccess"
          :multiple="false"
          :file-list="upload.fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { getVideoPage, refreshVideo, createVideo } from '@/service/dyVideo';
import { getOpenInfoByUser } from '@/service/dyUser';
import store from '@/store';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        code: [
          { required: true, message: '角色代码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
      },
      upload: {
        url: process.env.VUE_APP_APIHOST + '/dy_video/upload_video',
        headers: {
          Authentication: store.getters.token
        },
        fileList: []
      },
      dyUser: [],
      uploadForm: {},
      createForm: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getVideoPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {};
        this.uploadForm = {};
        getOpenInfoByUser().then(response => {
          this.dyUser = response['data'];
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
      this.getList();
    },
    /** 提交按钮 */
    submitForm: function() {
      this.cancel();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleSuccess(){

    },
    handleRefreshMedia(){
      refreshVideo().then(() => {
        this.getList();
        this.$message({
            type: 'success',
            message: '刷新成功!'
        });
      });
    },
    handleCreateVideo(){
      createVideo(this.createForm).then(() => {
        this.$message({
            type: 'success',
            message: '刷新成功!'
        });
      });
    }
  }
};
</script>